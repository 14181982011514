@import "~react-image-gallery/styles/css/image-gallery.css";

/* for image cros icon */
.container {
  position: relative;
}
.container img {
  display: block;
}
.container .fa-download {
  position: absolute;
  bottom: 0;
  left: 0;
}
/* for image cros icon end  */
/* Add gratitude button and text screen   */
.cards-space-affirmation {
  width: 18rem;
  margin-top: 20px !important;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.pods-cards-shadow {
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  border-radius: 16px;
  margin: -2px;
}
.image-div {
  border: 1px #198ba8;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
}
.h2-heading {
  color: var(--portal-theme-primary);
}
.pods-active-members {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: -20px !important;
  color: var(--text-secondary-color) !important;
}
.pods-image {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.memory-heading {
  padding-top: -1px !important;
}
.margin-quotes {
  margin-bottom: 30px !important;
}
.photo-row {
  padding-left: 12px;
  padding-right: 12px;
}
.delete-icon {
  position: relative;
  padding: 2px !important;
}
.delete-icon-position {
  position: absolute;
  right: 8px;
  margin-top: 8px;
  color: white;
  font-weight: 800;
  cursor: pointer;
}
/* .previews img {
  height: 76px !important;
  width: 72px !important;
} */
.responce-statement p {
  margin-bottom: 0 !important;
}
.cross-icon {
  width: 23px;
  color: #f06060;
  height: 22px;
  background-color: var(--portal-theme-secondary);
  border-radius: 18px;
  padding-top: 0px;
  /* background-color: var(--portal-theme-secondary); */
}
.active,
.dot:hover {
  background-color: rgba(158, 163, 161, 0.08) !important;
}
.padding-screen {
  padding-left: 12px;
  padding-right: 24px;
  /* padding-top: 7px; */
}
.add-new-photos .preview span {
  position: absolute;
  right: -9px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: white;
  text-align: center;
  border-radius: 10px;
}

/* .add-new-photos .previews span {
  top: -10px !important;
  right: -8px !important;
} */
/* cards css */
.cards {
  width: 600px;
  background-color: var(--background-secondary-color);
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
}

.cards p {
  color: rgb(141, 140, 140);
}
.cards h4 {
  color: rgb(65, 62, 62);
}
.cards-edit {
  width: 850px;
  background-color: var(--background-secondary-color);
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
}

.image img {
  transition: all 0.5s;
  border-radius: 50px;
}

.name {
  font-size: 22px;
  font-weight: bold;
}

.number {
  font-size: 22px;
  font-weight: bold;
}

.follow {
  font-size: 14px;
  font-weight: 500;
  color: #444444;
}

.btn1 {
  height: 40px;
  width: 150px;
  border: none;
  background-color: #000;
  color: #aeaeae;
  font-size: 15px;
}
.edit-profile-icon {
  position: relative;
}
.edit-profile-icon-position {
  position: absolute;
  right: -10px;
  top: 66px;
}
.icons i {
  font-size: 19px;
}

hr .new1 {
  border: 1px solid;
}

.join {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: bold;
}

.date {
  background-color: #ccc;
}
/* cards css end here */
/* kims reply screen */
.card-width {
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 5%);
  border-radius: 12px;
  background-color: var(--sidebars-backgroung-color);
}
.kimreply-screen-card-image {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}
.kims-reply {
  margin-bottom: 32px;
}
.circle-image {
  padding: 0;
}
.circle-image img {
  border-radius: 100%;
  padding: 9px;
  width: 60px;
  height: 60px;
  z-index: 1;
  cursor: pointer;
}

.help-text {
  margin-top: -21px;
}

.thumb {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 19px;
  border: 1px solid #bbb;
  line-height: 37px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;
}

.fw-500 {
  font-weight: 500 !important;
}

.thumbs-up:hover {
  color: #fff;
  background-color: green;
  border: 1px solid green;
}

.thumbs-down:hover {
  color: #fff;
  background-color: red;
  border: 1px solid red;
}
/* kims reply screen end  */
/* Edit card here */
.form-control:focus {
  box-shadow: none;
  border-color: #ba68c8;
}

.profile-button {
  background: rgb(99, 39, 120);
  box-shadow: none;
  border: none;
}

.profile-button:hover {
  background: #682773;
}

.profile-button:focus {
  background: #682773;
  box-shadow: none;
}

.profile-button:active {
  background: #682773;
  box-shadow: none;
}

.back:hover {
  color: #682773;
  cursor: pointer;
}

.labels {
  font-size: 11px;
}

.add-experience:hover {
  background: #ba68c8;
  color: #fff;
  cursor: pointer;
  border: solid 1px #ba68c8;
}
/* billing card  */

.card-margin {
  margin-bottom: 1.875rem;
}

.card-billing {
  border: 0;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card-billing {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--sidebars-backgroung-color);
  background-clip: border-box;
  border: 0px solid #e6e4e9;
  border-radius: 8px;
}

.card-billing .card-header.no-border {
  border: 0;
}
.card-billing .card-header {
  background: none;
  padding: 0 0.9375rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-height: 50px;
}
.card-header:first-child {
  border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}

.widget-49 .widget-49-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #edf1fc;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-primary
  .widget-49-date-day {
  color: #4e73e5;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-primary
  .widget-49-date-month {
  color: #4e73e5;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fcfcfd;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-secondary
  .widget-49-date-day {
  color: #dde1e9;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-secondary
  .widget-49-date-month {
  color: #dde1e9;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e8faf8;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-success
  .widget-49-date-day {
  color: #17d1bd;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-success
  .widget-49-date-month {
  color: #17d1bd;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebf7ff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info .widget-49-date-day {
  color: #36afff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info .widget-49-date-month {
  color: #36afff;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: floralwhite;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-warning
  .widget-49-date-day {
  color: #ffc868;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-warning
  .widget-49-date-month {
  color: #ffc868;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #feeeef;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger .widget-49-date-day {
  color: #f95062;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-danger
  .widget-49-date-month {
  color: #f95062;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fefeff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light .widget-49-date-day {
  color: #f7f9fa;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-date-light
  .widget-49-date-month {
  color: #f7f9fa;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebedee;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark .widget-49-date-day {
  color: #394856;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark .widget-49-date-month {
  color: #394856;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0fafb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base .widget-49-date-day {
  color: #68cbd7;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base .widget-49-date-month {
  color: #68cbd7;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  /* margin-left: 1rem; */
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-meeting-info
  .widget-49-pro-title {
  color: var(--text-secondary-color);
  font-size: 14px;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-meeting-info
  .widget-49-meeting-time {
  color: #b1bac5;
  font-size: 13px;
}

.widget-49 .widget-49-meeting-points {
  font-weight: 400;
  font-size: 13px;
  margin-top: 0.5rem;
}

.input-style-accordian {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #888888;
  font-weight: bold;
  cursor: pointer;
  height: 60px;
  /* Icon */
}

.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #68cbd7;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}
input:checked + .tab-label {
  background: #1a93a9;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input + .tab-label::after {
  transform: rotate(270deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}
.modalIcon {
  cursor: pointer;
}
/* payment screen  */

.card-payment {
  background: #16181a;
  border-radius: 14px;
  max-width: 340px;
  display: block;
  margin: auto;
  padding: 60px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 2px 2px 10px black;
  z-index: 99;
}

.logo-card {
  max-width: 50px;
  margin-bottom: 15px;
  margin-top: -19px;
}

.card-payment label {
  display: flex;
  font-size: 10px;
  color: white;
  opacity: 0.4;
}

.card-payment input {
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  color: #dbdce0;
  transition: border-bottom 0.4s;
}
.card-payment input:focus {
  border-bottom: 1px solid #fff;
  outline: none;
}

.cardnumber {
  display: block;
  font-size: 20px;
  margin-bottom: 8px;
}

.name {
  display: block;
  font-size: 15px;
  max-width: 200px;
  float: left;
  margin-bottom: 15px;
}

.toleft {
  float: left;
}
.ccv {
  width: 50px;
  margin-top: -5px;
  font-size: 15px;
}

.receipt {
  background: white;
  border-radius: 4px;
  padding: 5%;
  padding-top: 200px;
  max-width: 600px;
  display: block;
  margin: auto;
  margin-top: -180px;
  z-index: -999;
  position: relative;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
}

.receipt .col {
  width: 50%;
  float: left;
}
.bought-item {
  background: #f5f5f5;
  padding: 2px;
}
.bought-items {
  margin-top: -3px;
}

.cost {
  color: #1a93a9;
}
.seller {
  color: #1a93a9;
}
.description {
  font-size: 13px;
}
.price {
  font-size: 12px;
}
.comprobe {
  text-align: center;
}
.proceed {
  position: absolute;
  transform: translate(300px, 10px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #1a93a9;
  border: none;
  color: white;
  transition: box-shadow 0.2s, transform 0.4s;
  cursor: pointer;
}
.proceed:active {
  outline: none;
}
.proceed:focus {
  outline: none;
  box-shadow: inset 0px 0px 5px white;
}
.sendicon {
  filter: invert(100%);
  padding-top: 2px;
}

@media (max-width: 600px) {
  .proceed {
    transform: translate(250px, 10px);
  }
  .receipt .col {
    display: block;
    margin: auto;
    width: 100%;
    text-align: center;
  }
}
/* payment screen ends here */
/* Change card  */
.credit-card {
  border: solid 1px black;
}
.how-section1 {
  margin-top: -15%;
  padding: 10%;
}
.how-section1 h4 {
  color: #ffa500;
  font-weight: bold;
  font-size: 30px;
}
.how-section1 .subheading {
  color: #3931af;
  font-size: 20px;
}
.how-section1 .row {
  margin-top: 10%;
}
.how-img {
  text-align: center;
}
.how-img img {
  width: 40%;
}
/* Change card ends here */
/* 90 day plan  */
.goal_achieved_white {
  color: white !important;
  font-size: 20px;
  margin-top: 27px;
}
.goal_achieved_white #date_text_string {
  font-size: 26px;
  margin-top: 4px;
  color: var(--portal-theme-primary);
}
.title-golden-large {
  color: var(--portal-theme-primary);
  line-height: 40px;
  font-size: 35px;
}
/* slider lable color */
.css-1l0glfa-MuiSlider-markLabel {
  color: var(--portal-theme-primary);
}
.add-earning-drawer {
  background-color: var(--sidebars-backgroung-color);
}
/* 90 day plan  end here*/
/* Dashboard screen */
.chart-adjust {
  margin-top: 0;
}
.chart-adjust {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.svg-links {
  opacity: 0.8;
}
.svg-links:hover {
  opacity: 1;
}
/* slider icon  */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 50px;
  width: 50px;
  background-size: 100%, 100%;
  border-radius: 50%;
  /* border: ; */
  background-color: var(--portal-theme-secondary);
}

/* .carousel-control-next-icon:after {
    content: ">";
    font-size: 55px;
    color: red;
  } */

/* .carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: red;
} */
/* slider icon ends */
/* the slides */
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.card-border {
  border: 1px solid var(--portal-theme-primary);
}

/* Dashboard screen ends here */
.menuActive {
  color: var(--portal-theme-primary);
}
/* .menuActive:hover {
  color: var(--portal-theme-primary);
  color: green;
} */

.profile-cards {
  background-color: var(--background-secondary-color);
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  border-radius: 10px;
  min-height: 170px;
}

.profile-buttons {
  padding-left: 200px;
}
.profile-bg {
  position: relative;
}
.profile-image {
  position: absolute;
  bottom: -30px;
  left: 30px;
  border-radius: 5px;
}
.profile-image img {
  border: 5px solid #fff;
  width: 120px;
  height: 120px;
  border-radius: 5px;
}

.profile-name {
  margin-left: 50px;
  margin-top: 30px;
}
.poster-name .card-title {
  font-weight: 600;
}
.profile-cards .new-memories label {
  font-size: 14px;
  color: var(--text-secondary-color);
}
.profile-cards .new-memories textarea {
  font-size: 14px;
}
.poster-name .date-color {
  font-size: 12px !important;
}
.profile-ticket-button-contained {
  background-color: var(--button-outlined-color);
  padding: 4px 16px;
  color: var(--input-text-color);
  border-radius: 5px;
  border: 1px solid var(--button-outlined-color);
  cursor: pointer;
}

.profile-ticket-button-outlined {
  background-color: var(--button-background-color);
  padding: 4px 16px;
  color: var(--input-text-color);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.profile-gallery-photo {
  padding: 0 5px !important;
  text-align: center;
}

.profile-gallery-photo span {
  margin-top: 5px;
  display: block;
  font-size: 16px;
}

.profile-gallery-photo img {
  border-radius: 5px;
}
.avatar-group .MuiAvatar-circular {
  width: 30px;
  height: 30px;
  font-size: 12px;
}
.comment-icon .svg-color svg {
  width: 20px;
  height: 20px;
  margin-top: -1px;
}
.comment-image img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
}
.twiter-links a {
  text-decoration: none;
  color: var(--portal-theme-primary);
}
.profile-bars {
  position: absolute;
  right: 0px;
  display: none;
}

.profile-cards .date-color {
  font-weight: 400 !important;
}

.comment-submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);
  padding: 4px 15px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.reduce-margin-from-para {
  font-size: 11px;
  margin-top: 6px;
}

.community-card-title h4 {
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 3px;
  margin-bottom: 0;
  color: #fff;
}

.community-card-title h5 {
  font-size: 12px;
}

.community-card-title h6 {
  font-size: 10px;
  color: var(--text-secondary-color);
  margin-top: 10px;
}

.community-card-title .favorite-icon img {
  width: 20px;
  height: 20px;
}

.community-card-title .comment-image img {
  border-radius: 0%;
}

.community-card-title .card-title {
  font-size: 10px;
  font-weight: 200;
}

@media only screen and (max-width: 769px) {
  .cards-space-affirmation {
    width: 18rem;
    margin-top: 20px;
    margin-left: 65px;
  }
  .profile-gallery-photo {
    padding: 5px;
    margin-top: 5px !important;
  }
}

@media only screen and (max-width: 576px) {
  .profile-tabs {
    display: none;
  }
  .profile-bars {
    display: block;
  }
}
